<template>
    <div>
        <Horizontal v-if="$store.getters.currentPageVersion === 'Horizontal'"
        :welfareDetail="welfareDetail"></Horizontal>
        <Vertical v-else
        :welfareDetail="welfareDetail"></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Welfare/WelfareDetail/Horizontal/Index.vue'
    import Vertical from '@/components/Welfare/WelfareDetail/Vertical/Index.vue'
    

    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
               welfareDetail:{}
            }
        },
        created() {
            this.getGiftDetail()
        },
        methods:{
           getWelfareDetail() {
               console.log(this.$route.params.info)
               this.welfareDetail = this.$route.params.info
           }
        }
    }
</script>

<style scoped>
  
</style>
<template>
    <div class="all-page">
        <TopNav title="福利详情" backPath="WelfareIndex"></TopNav>
        <div class="all-page-content-box">
            <div class="gift-info-box">
                <div class="gift-item-box">
                    <div class="gift-info-title">
                        <div></div>礼包名称
                    </div>
                    <div class="gift-info-content">
                        新手礼包
                    </div>
                </div>
                <div class="gift-item-box">
                    <div class="gift-info-title">
                        <div></div>礼包详情
                    </div>
                    <div class="gift-info-content">
                        新手礼包
                    </div>
                </div>
                <div class="gift-item-box">
                    <div class="gift-info-title">
                        <div></div>有效期
                    </div>
                    <div class="gift-info-content">
                        新手礼包
                    </div>
                </div>
                <div class="gift-item-box">
                    <div class="gift-info-title">
                        <div></div>领取条件
                    </div>
                    <div class="gift-info-content">
                        新手礼包
                    </div>
                </div>
                <div class="gift-item-box">
                    <div class="gift-info-title">
                        <div></div>领取说明
                    </div>
                    <div class="gift-info-content">
                        新手礼包
                    </div>
                </div>
            </div>
            <div class="get-btn-box">
                <div class="get-btn-gray" v-if="welfareDetail.getState===1">
                    已领取
                </div>
                <div class="get-btn" v-else>
                    领取
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    export default {
        components: {
            TopNav
        },
        props: {
           welfareDetail:Object
        },
        data() {
            return {
                
            }
        },
        methods: {
           
        }
    }
</script>

<style scoped>
   .use-explain-title {
        font-size: 11px;
        font-weight: 500;
    }
    .use-explain-content {
        padding: 5px 0 11px 0;
        font-size: 10px;
        font-weight: 400;
        color: #333333;
    }
   .gift-item-box {
       padding-bottom: 7px;
   }
   .gift-info-title {
       font-size: 11px;
       font-weight: 400;
       color: #000000;
       display: flex;
       align-items: center;
       justify-content: flex-start;
   }
   .gift-info-title>div {
       width: 3px;
       height: 3px;
       border-radius: 50%;
       background-color: #16C5CE;
       margin-right: 2px;
   }
   .gift-info-content {
       font-size: 10px;
       font-weight: 400;
       line-height: 14px;
       color: #666666;
   }
   .get-btn-box {
       padding-top: 56px;
   }
   .get-btn-gray,
   .get-btn {
       width: 100%;
       height: 29px;
       border-radius: 4px;
       font-size: 12px;
       font-weight: 400;
       color: #FFFFFF;
       display: flex;
       align-items: center;
       justify-content: center;
   }
   .get-btn-gray {
       background-color: #CCCCCC;
   }
   .get-btn {
       background-color: #16C5CE;
   }
 </style>
